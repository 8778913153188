import { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import {
  lightThemeVariables,
  darkThemeVariables,
  commonThemeVariables,
  antOverrideCssVariables,
} from './themes/themeVariables';

export default createGlobalStyle`

  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }

  .ant-list-item-meta-description {
    color: var(--text-light-color);
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-overlay {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      width: 323px;
    }
  }

  a {
    color: var(--primary-color);
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }

  // notifications start
  .ant-notification-notice {
    width: 100%;
    min-width: 25vw;
    padding: 0.5rem 1rem;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &-success {
      box-shadow: 0px 0px 0px var(--success-color), 0px 5px 10px var(--success-color);
    }

    &-error {
      box-shadow: 0px 0px 0px var(--error-color), 0px 5px 10px var(--error-color);
    }

    &-info {
      box-shadow: 0px 0px 0px var(--info-color), 0px 5px 10px var(--info-color);
    }

    &-warning {
      box-shadow: 0px 0px 0px var(--warning-color), 0px 5px 10px var(--warning-color);
    }

    .ant-notification-notice-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
    }

    .ant-notification-notice-with-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      justify-content: flex-start;
    }
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin: 0;
      width: 100%;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      overflow: auto;
      margin-top: 0;
      margin-left: 0;
    }

    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }

    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }
    }
    
    .title {
      font-size: ${FONT_SIZE.xs};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
    
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.xs};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }

      &.false {
        display: none;
      }
  }
  
    .description {
      color: var(--text-light-color);
      font-size: ${FONT_SIZE.xs};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
      padding-right: 2rem;
      flex-basis: 100%;
      width: 100%;
    }

    .ant-notification-notice-icon {
      position: relative;
    }

    .icon-wrapper {
        position: relative;
        width: 30px;
        height: 30px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;

        &::before {
          content: '';
          background-color: var(--text-light-color);
          width: 2rem;
          height: 2rem;
          border-radius: 8px;
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          z-index: -1;
      }

      &.success::before {
        background-color: var(--success-color);
      }

      &.error::before {
        background-color: var(--error-color);
      }

      &.warning::before {
        background-color: var(--warning-color);
      }

      &.info::before {
        background-color: var(--primary-color);
      }
    }

    .success-icon, .info-icon, .warning-icon, .error-icon {
      font-size: 1rem; 
      position: relative;
      display: flex;
      align-self: center;
      justify-self: center;
      margin-left: 0.15rem;
      margin-top: 0.15rem;
      color: #fff;
    }
    
    .info-icon::before {
      background-color: var(--primary-color);
    }
  
    .warning-icon::before {
      background-color: var(--primary-color);
    }
  
    .error-icon::before {
      background-color: var(--error-color);
    }
  }
  
  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }

  .notification-without-title {
    .ant-notification-notice-message {
      display: none;
    }

    .ant-notification-notice-description {
      padding-left: 1rem;
    }
  }
    .delete-btn {
      transition: all 0.3s ease !important;

      &:hover {
        color: var(--error-color) !important;
      }
    }
`;
