import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import * as S from './IntegrationSettings.styles';
import { doCreateIntegration, doGetIntegrations } from '@app/store/slices/integrationSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { format } from 'date-fns';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Option } from '@app/components/common/selects/Select/Select';
import {
  INTEGRATION_PARTNER_URLS,
  IntegrationSoftware,
  IntegrationSoftwareWithExternalLink,
  IntegrationSoftwareWithExternalLinkAndSaveButton,
} from '@app/domain/IntegrationModel';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import {
  deleteIntegrationId,
  readIntegrationId,
  readIntegrationOrganizationId,
  readIntegrationRedirectUri,
  readIntegrationType,
} from '@app/services/localStorage.service';
import { useNavigate } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { PhoneOutlined } from '@ant-design/icons';
import { H3 } from '@app/components/common/typography/H3/H3';
import { H5 } from '@app/components/common/typography/H5/H5';
import { H6 } from '@app/components/common/typography/H6/H6';
import { IntegrationSettingsEmail } from './IntegrationSettingsEmail';

const INTEGRATION_OPTIONS = ['', ...Object.keys(IntegrationSoftware).filter((v) => isNaN(Number(v)))];
const GENERATED_TOKEN: string =
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8) +
  Math.random().toString(36).substring(2, 8);

export const IntegrationSettings: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showIntegrationSettingsEmail, setShowIntegrationSettingsEmail] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState<IntegrationSoftware>();
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  const currentIntegrations = useAppSelector((state) => state.integration.integrations);

  const dispatch = useAppDispatch();

  const submitForm = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        doCreateIntegration({ token: token, software: selectedSoftware as unknown as IntegrationSoftware }),
      );
      await dispatch(doGetIntegrations());
      setIsLoading(false);
      notificationController.success({
        message: t('profile.nav.integrationSettings.success'),
      });
    } catch (error) {
      console.log('error fetching integrations', error);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      if (user) {
        try {
          dispatch(doGetIntegrations());
        } catch (error) {
          console.log('error fetching integrations', error);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    const savePendingIntegration = async () => {
      const integrationId = readIntegrationId();
      const integrationType = readIntegrationType();
      const integrationOrganizationId = readIntegrationOrganizationId();

      // save pending integration from cookie
      if (
        dispatch &&
        user &&
        integrationId &&
        integrationType &&
        Object.values(IntegrationSoftware).includes(integrationType as IntegrationSoftware)
      ) {
        try {
          setIsLoading(true);
          let tokenToUse = integrationId;
          let userIdentifierToUse = null;
          let integrationOrganizationIdToUse = null;
          if (integrationType === IntegrationSoftware.Stripe) {
            userIdentifierToUse = integrationId;
            tokenToUse = GENERATED_TOKEN;
            integrationOrganizationIdToUse = integrationOrganizationId;
          }

          const result = await dispatch(
            doCreateIntegration({
              token: tokenToUse,
              software: integrationType as unknown as IntegrationSoftware,
              ...(userIdentifierToUse && { userIdentifier: userIdentifierToUse }),
              ...(integrationOrganizationIdToUse && { organizationIdentifier: integrationOrganizationIdToUse }),
            }),
          );
          if (result.type.includes('fulfilled')) {
            deleteIntegrationId();
            await dispatch(doGetIntegrations());
            notificationController.success({
              message: integrationType + ' ' + t('profile.nav.integrationSettings.success'),
            });
            if (integrationType !== IntegrationSoftware.Stripe) {
              navigate('/');
            } else {
              const redirectUrl = readIntegrationRedirectUri();
              if (redirectUrl) {
                window.location.href = redirectUrl;
              }
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.log('error fetching integrations', error);
        }
      }
    };

    savePendingIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showIntegrationSettingsEmail) {
    return <IntegrationSettingsEmail />;
  }

  return (
    <BaseButtonsForm.Item
      style={{
        padding: 20,
      }}
    >
      <S.Description style={{ whiteSpace: 'pre-line' }}>
        {t('profile.nav.integrationSettings.description')}
      </S.Description>
      {currentIntegrations && currentIntegrations[0] && (
        <>
          <H5>
            {t('profile.nav.integrationSettings.current') + ': '}
            {t('integrations.' + currentIntegrations[0].software)} -{' '}
            {format(new Date(currentIntegrations[0].updatedAt), 'dd.MM.yyyy')}
            {currentIntegrations[0].userIdentifier ? ' (' + currentIntegrations[0].userIdentifier + ')' : ''}
          </H5>

          {[IntegrationSoftware.Custom, IntegrationSoftware.Stripe].includes(currentIntegrations[0].software) ? (
            <H6>{t('profile.nav.integrationSettings.token') + ': ' + currentIntegrations[0].token}</H6>
          ) : (
            ''
          )}
          <br />
        </>
      )}
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <BaseButtonsForm.Item>
            <H3>{t('profile.nav.integrationSettings.new')}</H3>
          </BaseButtonsForm.Item>
          <BaseForm
            initialValues={{
              software: '',
              token:
                selectedSoftware && [IntegrationSoftware.Custom, IntegrationSoftware.Stripe].includes(selectedSoftware)
                  ? GENERATED_TOKEN
                  : '',
            }}
            fields={[
              {
                name: ['software'],
                value: selectedSoftware,
              },
              {
                name: ['token'],
                value: token,
              },
            ]}
          >
            <S.FormContent>
              <BaseForm.Item
                name="software"
                label={t('profile.nav.integrationSettings.software')}
                rules={[{ required: true }]}
              >
                <S.Select
                  showSearch
                  optionFilterProp="key"
                  filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
                  onSelect={(value) => {
                    setSelectedSoftware(value as unknown as IntegrationSoftware);
                    if (
                      [IntegrationSoftware.Custom, IntegrationSoftware.Stripe].includes(
                        value as unknown as IntegrationSoftware,
                      )
                    ) {
                      setToken(GENERATED_TOKEN);
                    }
                  }}
                >
                  {INTEGRATION_OPTIONS.map((item) => (
                    <Option key={t('integrations.' + item).toString()} value={item}>
                      {item ? t('integrations.' + item) : ''}
                    </Option>
                  ))}
                </S.Select>
              </BaseForm.Item>
              {selectedSoftware &&
                (!IntegrationSoftwareWithExternalLink.includes(selectedSoftware) ||
                  IntegrationSoftwareWithExternalLinkAndSaveButton.includes(selectedSoftware)) && (
                  <>
                    <BaseForm.Item
                      name="token"
                      hidden={[IntegrationSoftware.Custom, IntegrationSoftware.Stripe].includes(selectedSoftware)}
                      label={t('profile.nav.integrationSettings.token')}
                      rules={[{ required: true }]}
                    >
                      <Input
                        readOnly={[IntegrationSoftware.Custom, IntegrationSoftware.Stripe].includes(selectedSoftware)}
                        onChange={(e) => setToken(e.target.value)}
                        style={inputStyle}
                      />
                    </BaseForm.Item>
                    <Button
                      loading={isLoading}
                      disabled={token === '' || isLoading}
                      type="primary"
                      onClick={() => submitForm()}
                    >
                      {t('common.save')}
                    </Button>
                  </>
                )}
              {selectedSoftware && IntegrationSoftwareWithExternalLink.includes(selectedSoftware) && (
                <Button
                  style={{ marginTop: 10 }}
                  loading={isLoading}
                  disabled={isLoading}
                  type="ghost"
                  icon={<LinkOutlined />}
                  onClick={() => window.open(INTEGRATION_PARTNER_URLS.get(selectedSoftware), '_blank')}
                >
                  {t('profile.nav.integrationSettings.topartner')}
                </Button>
              )}
            </S.FormContent>
          </BaseForm>
        </Col>
      </Row>
      <br />
      {currentIntegrations && currentIntegrations[0] && user?.imageUrl ? (
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <BaseButtonsForm.Item>
              <H3>{t('profile.nav.integrationSettings.whitelabel')}</H3>
            </BaseButtonsForm.Item>
            <S.Description style={{ whiteSpace: 'pre-line' }}>
              {t('profile.nav.integrationSettings.whitelabelDescription')}
            </S.Description>
            <Button
              loading={isLoading}
              disabled={isLoading || !currentIntegrations || !currentIntegrations[0]}
              type="primary"
              icon={<LinkOutlined />}
              onClick={() => setShowIntegrationSettingsEmail(!showIntegrationSettingsEmail)}
            >
              {t('profile.nav.integrationSettings.linkEmail')}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <BaseButtonsForm.Item>
              <H3>{t('profile.nav.integrationSettings.whitelabel')}</H3>
            </BaseButtonsForm.Item>
            <S.Description style={{ whiteSpace: 'pre-line' }}>
              {t('profile.nav.integrationSettings.whitelabelTeaser')}
            </S.Description>
            <Button
              type="primary"
              icon={<PhoneOutlined />}
              onClick={() => window.open('https://www.debtist.de/book/', '_blank')}
            >
              {t('profile.nav.integrationSettings.learnMore')}
            </Button>
          </Col>
        </Row>
      )}
    </BaseButtonsForm.Item>
  );
};

const inputStyle: React.CSSProperties = {
  width: '100%',
  minWidth: '100%',
};
