import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doGetUser, doLogin } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { readIntegrationId, readUser } from '@app/services/localStorage.service';

interface LoginFormData {
  username: string;
  userId: string;
  password: string;
}

export const initValues: LoginFormData = {
  username: '',
  userId: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  let showUserPicker = false;

  const user = readUser();
  if (user && user.roles && user.roles.toString().includes('anager')) {
    showUserPicker = true;
  }

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);

    if (values.userId) {
      dispatch(doGetUser({ userId: values.userId }))
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate('/claims/debtcollection');
        });
      return;
    }

    values.username = values.username?.trim();
    values.password = values.password?.trim();

    dispatch(doLogin(values))
      .unwrap()
      .then(() => {
        const user = readUser();
        if (user) {
          dispatch(doGetUser({ userId: user?.id }));
        }

        // check for unsaved integrations
        const integrationId = readIntegrationId();
        if (integrationId) {
          navigate('/profile/integration-settings');
        } else {
          navigate('/claims/debtcollection');
        }
      })
      .catch(() => {
        notificationController.error({ message: t('login.loginFailed') });
        setLoading(false);
      });
  };

  return (
    <>
      <Auth.FormWrapper>
        <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
          <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
          <S.LoginDescription>{t('login.loginInfo')}</S.LoginDescription>
          {showUserPicker ? (
            <>
              <Auth.FormItem
                name="userId"
                label={t('claim.id')}
                validateTrigger={'onBlur'}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInput placeholder={t('claim.id')} />
              </Auth.FormItem>
            </>
          ) : (
            <>
              <Auth.FormItem
                name="username"
                label={t('common.email')}
                validateTrigger={'onBlur'}
                rules={[
                  { required: true, message: t('common.requiredField') },
                  {
                    type: 'email',
                    message: t('common.notValidEmail'),
                  },
                ]}
              >
                <Auth.FormInput placeholder={t('common.email')} />
              </Auth.FormItem>
              <Auth.FormItem
                label={t('common.password')}
                name="password"
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <Auth.FormInputPassword placeholder={t('common.password')} />
              </Auth.FormItem>
              <Auth.ActionsWrapper>
                <Link to="/auth/forgot-password">
                  <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
                </Link>
              </Auth.ActionsWrapper>
            </>
          )}
          <BaseForm.Item noStyle>
            <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
              {t('common.login')}
            </Auth.SubmitButton>
          </BaseForm.Item>
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('login.noAccount')}{' '}
              <Link to="/auth/sign-up">
                <Auth.LinkText>{t('login.signupLink')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        </BaseForm>
      </Auth.FormWrapper>
    </>
  );
};
