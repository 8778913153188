import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Tooltip, Typography } from 'antd';
import { countryOptions } from '@app/components/forms/StepForm/Steps/Step2';
import { Input } from '@app/components/common/inputs/Input/Input';
import { Select } from '@app/components/common/selects/Select/Select.styles';
import { Option } from '@app/components/common/selects/Select/Select';
import ReactCountryFlag from 'react-country-flag';
import { CountriesSpace } from '../profile/profileCard/profileFormNav/nav/PersonalInfo/CountriesItem/CountriesItem.styles';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import axios from 'axios';
import { readToken } from '@app/services/localStorage.service';
import Lottie from 'react-lottie';
import { notificationController } from '@app/controllers/notificationController';
import { doUpdateUser } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { EditOutlined, InfoOutlined } from '@ant-design/icons';
import { UserModel } from '@app/domain/UserModel';
import { DEFAULT_LOTTIE_CONFIG } from '@app/utils/utils';

interface AddressFormData {
  id?: string;
  addressLine1: string;
  addressLine2?: string;
  postalcode: string;
  town: string;
  country: string;
}

interface AddressFormProps {
  user: UserModel | null;
}

export const AddressForm = ({ user }: AddressFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [addressForm] = BaseForm.useForm();
  const [currentAddress, setCurrentAddress] = useState<AddressFormData>();
  const [isAnimationRunning, setIsAnimationRunning] = useState<boolean>(false);
  // const [isFieldsChanged, setFieldsChanged] = useState(false);
  const { t } = useTranslation();
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const cardActions: React.ReactNode[] = [
    <EditOutlined
      style={{ color: 'var(--text-main-color)' }}
      key="edit"
      onClick={() => setEditAddress(!editAddress)}
    />,
  ];

  // Check if an address already exists
  const fetchCurrentAddress = async () => {
    if (!user?.address) {
      return;
    }
    const addressId = user.address?.id;

    await axios
      .get(`${process.env.REACT_APP_API_URL}/addresses/${addressId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${readToken()}`,
        },
      })
      .then((response) => {
        if (response.data) {
          if (!user.address?.id) {
            setCurrentAddress(
              response.data.filter((address: { user: { id: string } }) => address.user.id === user.id)[0],
            );
          } else {
            setCurrentAddress(response.data);
          }
        }
      })
      .catch((error) => {
        notificationController.error({
          message: error.message,
        });
        setIsAnimationRunning(false);
      });
  };

  const handleCancelPress = () => {
    setEditAddress(false);
    setIsLoading(false);
  };

  useEffect(() => {
    if (editAddress) {
      setIsLoading(true);
    }
    if (user?.address) {
      fetchCurrentAddress();
    }
    // eslint-disable-next-line
  }, [user?.address, editAddress]);

  const onAddressEntry = (values: AddressFormData) => {
    setIsLoading(true);

    if (!user) {
      setIsLoading(false);
      notificationController.error({ message: t('login.loginFailed') });
      return;
    }
    let payload;

    if (!currentAddress?.id) {
      payload = {
        ...values,
      };
    } else {
      payload = {
        id: currentAddress.id,
        ...values,
      };
    }

    dispatch(
      doUpdateUser({
        userId: user.id,
        address: payload,
      }),
    ).then((response) => {
      if (isLoading && !isAnimationRunning && response) {
        setIsAnimationRunning(true);

        setTimeout(() => {
          setIsLoading(false);
          setIsAnimationRunning(false);
          setEditAddress(false);
        }, 800); // animation duration
      }
    });
  };

  const Form = () => {
    return (
      <>
        <Col xs={24}>
          <BaseButtonsForm.Item
            name="addressLine1"
            label={t('profile.nav.account.address.addressLine1')}
            rules={[
              {
                required: true,
                message: t('profile.nav.account.address.addressLine1'),
              },
            ]}
          >
            <Input placeholder={t('profile.nav.account.address.addressLine1')} />
          </BaseButtonsForm.Item>
        </Col>

        <Col xs={24}>
          <BaseButtonsForm.Item
            name="addressLine2"
            label={t('profile.nav.account.address.addressLine2')}
            rules={[
              {
                required: false,
                message: t('profile.nav.account.address.addressLine2'),
              },
            ]}
          >
            <Input placeholder={t('profile.nav.account.address.addressLine2')} />
          </BaseButtonsForm.Item>
        </Col>

        <Col xs={24}>
          <BaseButtonsForm.Item
            name="postalcode"
            label={t('profile.nav.account.address.postalCode')}
            rules={[
              {
                required: true,
                message: t('profile.nav.account.address.postalCode'),
              },
            ]}
          >
            <Input placeholder={t('profile.nav.account.address.postalCode')} />
          </BaseButtonsForm.Item>
        </Col>

        <Col xs={24}>
          <BaseButtonsForm.Item
            name="town"
            label={t('profile.nav.account.address.town')}
            rules={[
              {
                required: true,
                message: t('profile.nav.account.address.town'),
              },
            ]}
          >
            <Input placeholder={t('profile.nav.account.address.town')} />
          </BaseButtonsForm.Item>
        </Col>

        <Col xs={24}>
          <BaseForm.Item
            name="country"
            label={t('profile.nav.account.address.country')}
            rules={[{ required: true, message: t('forms.stepFormLabels.countryError') }]}
          >
            <Select
              showSearch
              optionFilterProp="key"
              filterOption={(input, option) => option?.key?.toLowerCase().includes(input.toLowerCase())}
              defaultValue={'£££'}
            >
              {countryOptions.map((country) => (
                <Option
                  key={t('forms.stepFormLabels.country.' + country.code.toLowerCase()).toString()}
                  value={country.code}
                >
                  <CountriesSpace align="center">
                    <ReactCountryFlag countryCode={country.short} svg alt="country flag" />
                    {t('forms.stepFormLabels.country.' + country.code.toLowerCase())}
                  </CountriesSpace>
                </Option>
              ))}
            </Select>
          </BaseForm.Item>
        </Col>
      </>
    );
  };

  return (
    <Col xs={24} lg={12} style={{ marginBottom: 20 }}>
      <BaseButtonsForm.Title>{t('profile.nav.account.address.title')}</BaseButtonsForm.Title>
      {currentAddress && !editAddress && (
        <Col xs={24} style={{ marginLeft: -15 }}>
          <Card actions={cardActions} loading={isLoading} hoverable>
            <Card.Meta
              avatar={
                <ReactCountryFlag
                  countryCode={countryOptions.filter(({ code }) => code === currentAddress.country)[0]?.short}
                  svg
                  alt="country flag"
                  style={{
                    marginRight: 10,
                  }}
                />
              }
              title={t('profile.nav.account.address.current')}
              description={
                <>
                  <Typography.Text
                    style={{
                      display: 'block',
                    }}
                  >
                    {currentAddress.addressLine1}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                    }}
                  >
                    {currentAddress.addressLine2}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                    }}
                  >
                    {currentAddress.town}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                    }}
                  >
                    {currentAddress.postalcode}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                    }}
                  >
                    {t('forms.stepFormLabels.country.' + currentAddress.country?.toLowerCase())}
                  </Typography.Text>
                </>
              }
            />
          </Card>
        </Col>
      )}

      {!currentAddress && !editAddress && (
        <Card actions={cardActions} loading={isLoading} hoverable>
          <Tooltip title={t('profile.nav.account.address.noAddress')}>
            <div
              style={{
                position: 'absolute',
                top: -10,
                right: -10,
                backgroundColor: 'var(--ant-warning-color)',
                borderRadius: '50%',
                width: 30,
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <InfoOutlined style={{ color: 'var(--white)' }} />
            </div>
          </Tooltip>

          <Card.Meta title={t('profile.nav.account.address.noAddress')} />
        </Card>
      )}

      {editAddress && (
        <BaseButtonsForm
          form={addressForm}
          name="updateAddress"
          requiredMark="optional"
          isFieldsChanged={true}
          //   onFieldsChange={() => setFieldsChanged(true)}
          initialValues={
            currentAddress
              ? currentAddress
              : {
                  id: '',
                  country: 'DEU',
                }
          }
          onFinish={onAddressEntry}
          cancelLogic={() => handleCancelPress()}
          disabled={isAnimationRunning && isLoading}
        >
          <Row gutter={{ md: 15, xl: 30 }}>
            {isAnimationRunning ? <Lottie options={DEFAULT_LOTTIE_CONFIG} height={150} width={150} /> : <Form />}
          </Row>
        </BaseButtonsForm>
      )}
    </Col>
  );
};
