import React from 'react';
import { notification } from 'antd';
import styled from 'styled-components';
import { CheckCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined, StopOutlined } from '@ant-design/icons';
import { ArgsProps } from 'antd/lib/notification';

const EmptyDescription = styled.div`
  margin-top: -0.75rem;
`;

type NotificationProps = ArgsProps;

const openSuccessNotification = (config: NotificationProps): void => {
  notification.success({
    ...config,
    icon: (
      <div className="icon-wrapper success">
        <CheckCircleOutlined className="success-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className:
      !config.message || config.message === 'Success'
        ? 'notification-without-title'
        : !config.description
        ? 'notification-without-description'
        : 'test',
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.info({
    ...config,
    icon: (
      <div className="icon-wrapper info">
        <InfoCircleOutlined className="info-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className:
      !config.message || config.message === 'Information'
        ? 'notification-without-title'
        : !config.description
        ? 'notification-without-description'
        : 'test',
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.warning({
    ...config,
    icon: (
      <div className="icon-wrapper warning">
        <ExclamationCircleOutlined className="warning-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className:
      !config.message || config.message === 'Warning'
        ? 'notification-without-title'
        : !config.description
        ? 'notification-without-description'
        : 'test',
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.error({
    ...config,
    icon: (
      <div className="icon-wrapper error">
        <StopOutlined className="error-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && `title-only`}`}>{config.message}</div>,
    description: config.description ? <div className="description">{config.description}</div> : <EmptyDescription />,
    className:
      !config.message || config.message === 'Error'
        ? 'notification-without-title'
        : !config.description
        ? 'notification-without-description'
        : 'test',
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};
