import { PrepareAction, createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  login,
  LoginRequest,
  resetPassword,
  verifySecurityCode,
  SecurityCodePayload,
  GetUserRequest,
  getUser,
  getUserClaimsAsCreditor,
  GetUserClaimsByCreditorRequest,
  UpdateUserRequest,
  updateUser,
  CreateUserRequest,
  createUser,
  CurrentPasswordData,
  verifyCurrentPassword,
  UserResetPasswordPayload,
  NewResetPasswordPayload,
  setNewResetPassword,
  NewPasswordData,
  setNewPassword,
} from '@app/api/user.api';
import {
  deleteToken,
  deleteUser,
  persistToken,
  persistUser,
  readToken,
  readUser,
} from '@app/services/localStorage.service';
import { setClaimsAsCreditorDunning, setClaimsAsCreditorReminders } from './claimSlice';
import { UserModel } from '@app/domain/UserModel';
import { notificationController } from '@app/controllers/notificationController';

export interface AuthSlice {
  token: string | null;
  user: UserModel | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  user: readUser(),
};

export const doLogin = createAsyncThunk('auth/doLogin', async (loginPayload: LoginRequest, { dispatch }) =>
  login(loginPayload).then((response) => {
    dispatch(setUser({ id: response.id, username: response.username, email: response.username }));
    persistToken(response.accessToken);

    return response.accessToken;
  }),
);

export const doGetUser = createAsyncThunk('auth/doGetUser', async (getUserPayload: GetUserRequest, { dispatch }) =>
  getUser(getUserPayload)
    .then((response) => {
      dispatch(setUser(response));
    })
    .catch(() => {
      dispatch(doLogout());
    }),
);

export const doCreateUser = createAsyncThunk('auth/doCreateUser', async (createUserPayload: CreateUserRequest) =>
  createUser(createUserPayload),
);

export const doUpdateUser = createAsyncThunk(
  'auth/doUpdateUser',
  async (updateUserPayload: UpdateUserRequest, { dispatch }) =>
    updateUser(updateUserPayload)
      .then((response) => {
        if (!updateUserPayload.userId || updateUserPayload.userId === initialState?.user?.id) {
          // different user (debtor)
          dispatch(setUser(response));
        }
      })
      .catch((e) => {
        notificationController.error({
          message: e.message,
        });
      }),
);

export const doGetUserClaimsAsCreditor = createAsyncThunk(
  'auth/doGetUserClaimsAsCreditor',
  async (getUserClaimsAsCreditorPayload: GetUserClaimsByCreditorRequest, { dispatch }) =>
    getUserClaimsAsCreditor(getUserClaimsAsCreditorPayload).then((response) => {
      // probably not the cleanest solution
      if (getUserClaimsAsCreditorPayload.filters?.some((filter) => filter.stage === 'Reminder')) {
        dispatch(setClaimsAsCreditorReminders(response));
      } else {
        dispatch(setClaimsAsCreditorDunning(response));
      }
    }),
);

// step 1 initiate reset
export const doResetPassword = createAsyncThunk(
  'auth/doResetPassword',
  async (resetPasswordPayload: UserResetPasswordPayload) => resetPassword(resetPasswordPayload),
);

// step 2 verify security code
export const doVerifySecurityCode = createAsyncThunk(
  'auth/doVerifySecurityCode',
  async (securityCodePayload: SecurityCodePayload) => verifySecurityCode(securityCodePayload),
);

// step 3 set new password
export const doSetNewResetPassword = createAsyncThunk(
  'auth/doSetNewResetPassword',
  async (newResetPasswordPayload: NewResetPasswordPayload) => setNewResetPassword(newResetPasswordPayload),
);

// update password
export const doVerifyCurrentPassword = createAsyncThunk(
  'auth/verifyPassword',
  async (currentPassword: CurrentPasswordData) => verifyCurrentPassword(currentPassword),
);

export const doSetNewPassword = createAsyncThunk('auth/updatePassword', async (newPasswordData: NewPasswordData) =>
  setNewPassword(newPasswordData),
);

export const doLogout = createAsyncThunk('auth/doLogout', (_payload, { dispatch }) => {
  deleteToken();
  deleteUser();
  dispatch(setUser(null));
});

export const setUser = createAction<PrepareAction<UserModel>>('auth/setUser', (newUser) => {
  persistUser(newUser);

  return {
    payload: newUser,
  };
});

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      state.token = action.payload;
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = '';
    });
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
  },
});

export default authSlice.reducer;
