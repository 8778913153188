import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { Col, Row } from 'antd';
import React from 'react';
import { PasswordForm } from '../PersonalInfo/passwordForm/PasswordForm/PasswordForm';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doLogout } from '@app/store/slices/userSlice';
import { useTranslation } from 'react-i18next';

import PaymentInformationsForm from '@app/components/forms/PaymentInformationsForm';
import { AddressForm } from '@app/components/forms/AddressForm';
import VatForm from '@app/components/forms/VatForm';

export const AccountSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();

  const handleLogout = () => {
    dispatch(doLogout());
  };

  return (
    <BaseButtonsForm.Item
      style={{
        padding: 20,
      }}
    >
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <AddressForm user={user} />
        <VatForm user={user} />
        <PaymentInformationsForm user={user} />
        <Col xs={24} style={{ marginTop: 20 }}>
          <PasswordForm />
        </Col>
      </Row>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24}>
          <BaseButtonsForm.Title style={{ marginTop: 20 }}>{t('header.logout')}</BaseButtonsForm.Title>
          <Button type="primary" onClick={handleLogout}>
            {t('header.logout')}
          </Button>
        </Col>
      </Row>
    </BaseButtonsForm.Item>
  );
};
