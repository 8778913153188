import React from 'react';
import { FormOutlined, LogoutOutlined, TableOutlined, UserOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  disabled?: boolean;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.forms',
    key: 'forms',
    icon: <FormOutlined />,
    url: '/new/claim',
  },
  {
    title: 'common.dataTables',
    key: 'dataTables',
    icon: <TableOutlined />,
    children: [
      {
        title: 'common.dataTablesDunning',
        key: 'dataTablesDunning',
        url: '/claims/debtcollection',
      },
      {
        title: 'common.dataTablesReminder',
        key: 'dataTablesReminder',
        url: '/claims/reminders',
      },
    ],
  },
  {
    title: 'common.authPages',
    key: 'auth',
    icon: <UserOutlined />,
    children: [
      {
        title: 'profile.nav.personalInfo.title',
        key: 'profile',
        url: '/profile',
      },
      {
        title: 'profile.nav.account.title',
        key: 'settings',
        url: '/profile/account',
      },
      {
        title: 'profile.nav.integrationSettings.title',
        key: 'integrations',
        url: '/profile/integration-settings',
      },
      {
        title: 'profile.nav.payments.title',
        key: 'payments',
        url: '/profile/payments',
      },
      {
        title: 'profile.nav.appearanceSettings.title',
        key: 'appearance',
        url: '/profile/appearance',
      },
      {
        title: 'profile.nav.notifications.title',
        key: 'notifications',
        url: '/profile/notification',
      },
      {
        icon: <LogoutOutlined />,
        title: 'common.logout',
        key: 'logout',
        url: '/logout',
      },
    ],
  },
];
