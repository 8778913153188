import { References } from '@app/components/common/References/References';
import { MEDICAL_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { readToken } from '@app/services/localStorage.service';
import { doGetUser } from '@app/store/slices/userSlice';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from '../../../header/Header';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import MainSider from '../sider/MainSider/MainSider';
import * as S from './MainLayout.styles';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const accessToken: string = readToken();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if ((!accessToken || !user) && !location.pathname.toString().startsWith('/contract')) {
      navigate('/auth/login', {
        replace: true,
      });
      return;
    }
    if (user) {
      dispatch(doGetUser({ userId: user?.id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, dispatch, navigate]);

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, '/'].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  useEffect(() => {
    if (user?.email && user?.contactName) {
      const script = document.createElement('script');

      script.innerHTML =
        "function initFreshChat() { window.fcWidget.init({ token: '63053e4b-5b0c-40bc-9599-df0f9e3ea7be', host: 'https://debtistgmbh.freshchat.com' }); " +
        "window.fcWidget.setExternalId('" +
        (user?.email || '') +
        "'); window.fcWidget.user.setFirstName('" +
        user?.contactName +
        "'); window.fcWidget.user.setEmail('" +
        user?.email +
        "');" +
        " } function initialize(i,t){var e;i.getElementById(t)? initFreshChat():((e=i.createElement('script')).id=t,e.async=!0, e.src='https://debtistgmbh.freshchat.com/js/widget.js',e.onload=initFreshChat,i.head.appendChild(e))} function initiateCall(){initialize(document,'Freshchat-js-sdk')} window.addEventListener?window.addEventListener('load',initiateCall,!1): window.attachEvent('load',initiateCall,!1);";

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [user?.contactName, user?.email]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://euc-widget.freshworks.com/widgets/103000005177.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout && <References />}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
