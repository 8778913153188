import { DEFAULT_LOTTIE_CONFIG } from '@app/utils/utils';
import { Col, Tooltip, Card, Typography } from 'antd';
import { Input } from '@app/components/common/inputs/Input/Input';
import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import { UserModel } from '@app/domain/UserModel';
import { notificationController } from '@app/controllers/notificationController';
import { InfoOutlined, EditOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doUpdateUser } from '@app/store/slices/userSlice';
import { BaseForm } from '../common/forms/BaseForm/BaseForm';

interface VatIdFormData {
  vatId: string;
}

interface VatFormProps {
  user: UserModel | null;
}

const VatForm = ({ user }: VatFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isAnimationRunning, setIsAnimationRunning] = useState(false);
  const [vatIDForm] = BaseForm.useForm();
  const { t } = useTranslation();
  const [currentVatID, setCurrentVatID] = useState<VatIdFormData | undefined>(
    user?.vatId ? { vatId: user.vatId } : undefined,
  );
  const [editVatID, setEditVatID] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.vatId) {
      setCurrentVatID({ vatId: user.vatId });
    }
  }, [user?.vatId]);

  const onVatEntry = (values: VatIdFormData) => {
    setIsLoading(true);
    if (!user) {
      setIsLoading(false);
      notificationController.error({ message: t('login.loginFailed') });
      return;
    }

    dispatch(
      doUpdateUser({
        userId: user.id,
        vatId: values.vatId,
      }),
    ).then((response) => {
      if (response) {
        setIsAnimationRunning(true);
        setTimeout(() => {
          setIsLoading(false);
          setIsAnimationRunning(false);
          setCurrentVatID({ vatId: values.vatId });
          setEditVatID(false);
          notificationController.success({ message: t('forms.vatInfo.updateSuccess') });
        }, 800);
      } else {
        setIsLoading(false);
        notificationController.error({ message: t('forms.vatInfo.vatError') });
      }
    });
  };

  const cardActions: React.ReactNode[] = [
    <EditOutlined style={{ color: 'var(--text-main-color)' }} key="edit" onClick={() => setEditVatID(!editVatID)} />,
  ];

  return (
    <Col xs={24} md={12}>
      <BaseButtonsForm.Title>{t('claim.creditorVatId')}</BaseButtonsForm.Title>
      {currentVatID && !editVatID && (
        <Card actions={cardActions} loading={isLoading} hoverable>
          <Card.Meta
            title={t('forms.vatInfo.title')}
            description={<Typography.Text>{currentVatID.vatId}</Typography.Text>}
          />
        </Card>
      )}
      {(editVatID || !currentVatID) && (
        <BaseButtonsForm
          form={vatIDForm}
          name="updateVatId"
          requiredMark="optional"
          isFieldsChanged={isFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onVatEntry}
          initialValues={currentVatID}
          cancelLogic={() => setEditVatID(false)}
          disabled={isLoading}
        >
          {isAnimationRunning ? (
            <Lottie options={DEFAULT_LOTTIE_CONFIG} height={150} width={150} />
          ) : (
            <BaseButtonsForm.Item
              name="vatId"
              label={t('forms.vatInfo.title')}
              rules={[
                {
                  required: true,
                  message: t('forms.vatInfo.vatError'),
                },
              ]}
            >
              <Input placeholder={t('forms.vatInfo.vat')} />
            </BaseButtonsForm.Item>
          )}
          {!currentVatID && (
            <Tooltip title={t('forms.vatInfo.vatTooltip')}>
              <div
                style={{
                  position: 'absolute',
                  top: 60,
                  right: 0,
                  backgroundColor: 'var(--ant-warning-color)',
                  borderRadius: '50%',
                  width: 30,
                  height: 30,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <InfoOutlined style={{ color: 'var(--white)' }} />
              </div>
            </Tooltip>
          )}
        </BaseButtonsForm>
      )}
    </Col>
  );
};

export default VatForm;
