import { ClaimModel } from './ClaimModel';

export type PaymentModel = {
  amount: number;
  claim?: ClaimModel;
  createdAt: Date;
  id: string;
  isActive: boolean;
  isPlanned: boolean;
  paymentDate: Date | null;
  paymentType?:
    | 'DunningCostExpense'
    | 'DunningCostFee'
    | 'DefaultInterest'
    | 'ExistingCreditorExtras'
    | 'ExistingPayments'
    | 'WriteOffDiscount'
    | 'DebtClearance'
    | 'CreditorInterest'
    | 'PaymentRate'
    | 'Payout'
    | 'DebtCollectorTax'
    | 'DebtCollectorFee'
    | null;
  fileUrl?: string;
  reference: string | null;
  updatedAt: Date;
};

export enum EnumPaymentPaymentType {
  DunningCostExpense = 'DunningCostExpense',
  DunningCostFee = 'DunningCostFee',
  DefaultInterest = 'DefaultInterest',
  ExistingCreditorExtras = 'ExistingCreditorExtras',
  CreditorInterest = 'CreditorInterest',
  ExistingPayments = 'ExistingPayments',
  WriteOffDiscount = 'WriteOffDiscount',
  DebtClearance = 'DebtClearance',
  PaymentRate = 'PaymentRate',
  DebtCollectorTax = 'DebtCollectorTax',
  DebtCollectorFee = 'DebtCollectorFee',
  Payout = 'Payout',
}

export const NEGATIVE_PAYMENT_TYPES = [
  EnumPaymentPaymentType.WriteOffDiscount,
  EnumPaymentPaymentType.DebtClearance,
  EnumPaymentPaymentType.PaymentRate,
];
